





























































import Vue from 'vue'
import { ApiResponse, Contact } from '@/interfaces'
import { mapState } from 'vuex'
import AddContact from '@/components/AddContact.vue'

export default Vue.extend({
  components: { AddContact },
  props: {
    contact: {
      type: String,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    contactNumber: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      contacts: [] as Contact[],
      busy: false,
      selectedContactId: '',
      relation: '',
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    contact(newVal) {
      if (newVal && this.activeTab === 'contacts') {
        this.getContacts()
      }
    },
    activeTab(newVal) {
      if (newVal === 'contacts' && this.contact) {
        this.getContacts()
      }
    },
  },
  mounted() {
    if (this.contact && this.activeTab === 'contacts') {
      this.getContacts()
    }
  },
  methods: {
    getContacts() {
      if (!this.busy) {
        this.busy = true
        const contactId = Number(this.contact)
        this.$api
          .get('/contact/' + contactId + '/linkedcontacts')
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            //this.contacts = apiResponse.data.contacts
            this.contacts = apiResponse.data
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    editContact(contact: Contact) {
      if (contact.id) {
        this.$router.push({
          name: 'contact_edit',
          params: {
            id: contact.id.toString(),
          },
        })
      }
    },
    cellClick(row: Contact, column: Record<string, string>, cell: HTMLTableCellElement): void {
      if (!cell.classList.contains('actions')) {
        this.editContact(row)
      }
    },
    detachContact(contact: Contact) {
      const data = {
        parentContactId: null,
        relation: null,
      }
      this.$confirm('Êtes-vous sûr(e) de vouloir retirer ce contact de la liste ?', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(() => {
        this.$api
          .put(`/contact/${contact.id}`, data)
          .then(() => {
            this.getContacts()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    getCategory(category: string): string {
      return category //this.contactCategories?.find((item: SelectOption) => item.value === category)?.label ?? 'Contact'
    },
    getTypeIcon(type: string): string {
      switch (type) {
        case 'personne_physique':
          return 'contact-type-icon el-icon-user'

        case 'personne_morale':
          return 'contact-type-icon el-icon-suitcase-1'

        default:
          return 'contact-type-icon el-icon-question'
      }
    },
    selectedContact(contactId: string) {
      this.selectedContactId = contactId
    },
    addContact() {
      const contactId = Number(this.contact)
      //console.log('add ' + this.relation + ' ' + this.selectedContactId + ' to ' + contactId)
      const data = {
        parentContactId: contactId,
        relation: this.relation,
      }
      this.$api
        .put(`/contact/${this.selectedContactId}`, data)
        .then(() => {
          this.getContacts()
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
          })
        })
      // refreshlist
      //this.getContacts()
    },
  },
})

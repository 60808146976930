


























































































import Vue from 'vue'
import { ApiResponse, Contact } from '@/interfaces'
import { mapState } from 'vuex'
import ContactForm from './ContactForm.vue'

export default Vue.extend({
  components: { ContactForm },
  props: {
    contact: {
      type: Object as () => Contact,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      contacts: [] as Record<string, string | null>[],
      infoPrefill: null as string | null,
      loading: false,
      contactInfos: {
        siret: '',
        vat: '',
        fullName: '',
        formName: '',
        address: '',
        email: '',
        phone: '',
        mobile: '',
        companyName: '',
      },
      selectedContact: '',
      showAddContactModal: false,
      newContactId: null as string | null,
      resetContactForm: false,
      usedContact: false,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {},
  mounted() {
    this.busy = false
  },
  methods: {
    searchContacts(search?: string) {
      this.contacts = this.search(search)
    },
    search(search?: string, category = null as string | null, minLength = 2): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }

        this.infoPrefill = search

        this.loading = true
        //const structure = this.formModel.structureId
        let contacts = [] as Record<string, string | null>[]

        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
              //structure,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.loading = false
          })

        return contacts
      } else {
        return []
      }
    },
    clearNewContact() {
      //this.newContactId = ''
      //this.debiteurs = []
      //this.requerants = []
      //this.resetContactForm = !this.resetContactForm
      //this.updateUsedContact(false)
    },
    updateUsedContact(isUsed: boolean) {
      //this.usedContact = isUsed
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      //this.newContact = contact
      this.loadContact(contact?.id?.toString() ?? '')
    },
    nl2br(str: string): string {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    loadContact(id: string) {
      if (id === 'add') {
        this.openContactForm()
      } else {
        this.loadContactInfos(id)
      }
    },
    openContactForm(contactId?: string) {
      if (!this.busy) {
        this.showAddContactModal = true
        this.newContactId = contactId ?? null
      }
    },
    closeContactForm() {
      this.showAddContactModal = false
      this.infoPrefill = null
    },
    loadContactInfos(id: string) {
      if (id == '' || id == null) {
        return
      }
      this.busy = true
      this.$api
        .get(`/contact/${id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.contactInfos = this.setContactInfos(apiResponse.data)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    setContactInfos(contact: Contact | null) {
      let address = '-'
      if (contact?.address != null) {
        address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${
          contact?.city ?? ''
        }`
      }

      // this.updateContactInfos(contact, type)
      this.$emit('selectedContactTrigger', contact?.id)

      return {
        siret: contact?.siret ?? '-',
        vat: contact?.vat ?? '-',
        fullName: contact?.fullName ?? '-',
        formName: contact?.formName ?? '-',
        address,
        email: contact?.email ?? '-',
        phone: contact?.phone ?? '-',
        mobile: contact?.mobile ?? '-',
        companyName: contact?.companyName ?? '-',
      }
    },
  },
})
